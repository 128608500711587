<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        Статистика: {{ page_title.toLowerCase() }}
      </h3>

      <div class="mt-3 mb-2">
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/overall')"
               title="Общая">
          Общая
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/incoming')"
               title="Входящие звонки">
          Входящие звонки
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/all_tasks')"
               title="Все задачи">
          Все задачи
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/chats')"
               title="Чаты">
          Чаты
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/cars_owners')"
               title="Авто/Владельцы">
          Авто/Владельцы
        </label>
        <label class="btn btn-primary font-scale-2 font-medium">
          {{ page_title }}
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/credit_leasing')"
               title="Кредит/Лизинг">
          Кредит/Лизинг
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/exchange_buyout')"
               title="Обмен/Выкуп">
          Обмен/Выкуп
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/statistic/estimate_search_sale')"
               title="Оценка/Подбор/Продажа">
          Оценка/Подбор/Продажа
        </label>
      </div>

    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_statistic.branch_id = branch ? branch.id : null;
                             getStatistic();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_statistic.responsible_id = responsible ? responsible.id : null;
                             getStatistic();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <v-date-picker
                :masks="date_options.masks"
                :max-date="date_options.maxDate"
                :min-date="date_options.minDate"
                :mode="'date'"
                :model-config="date_options.formatDate"
                :update-on-input="false"
                color="blue"
                locale="ru"
                v-model="filter_statistic.date_from"
                @input="() => {
                  getStatistic();
                }"
                trim-weeks>
                <template v-slot="{inputValue, inputEvents}">
                  <input :value="inputValue"
                         class="form-control"
                         id="term_at"
                         placeholder="С даты"
                         v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <v-date-picker
                :masks="date_options.masks"
                :max-date="date_options.maxDate"
                :min-date="date_options.minDate"
                :mode="'date'"
                :model-config="date_options.formatDate"
                :update-on-input="false"
                color="blue"
                locale="ru"
                v-model="filter_statistic.date_to"
                @input="() => {
                  getStatistic();
                }"
                trim-weeks>
                <template v-slot="{inputValue, inputEvents}">
                  <input :value="inputValue"
                         class="form-control"
                         id="term_at"
                         placeholder="По дату"
                         v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </transition>

      <div class="row" v-if="onProgress === false">

      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BranchService from "@/services/BranchService";
import Multiselect from "vue-multiselect";
import moment from "moment";
import VCalendar from "v-calendar";

Vue.use(VCalendar, {});

moment.locale("ru");

const DATE_FORMAT = "YYYY-MM-DD";

export default {
  name: "statistic_call_center",

  components: {
    Multiselect,
  },

  props: {
    data_search: {
      type: String,
      required: false,
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Call-center",

    statistic: [],

    branches: [],
    branch: null,
    employees: [],
    responsible: null,

    filter_statistic: {
      branch_id: null,
      responsible_id: null,
      date_from: moment().subtract(7, "day").format(DATE_FORMAT),
      date_to: moment().format(DATE_FORMAT),
    },

    date_options: {
      minDate: moment().subtract(1, "year").format(DATE_FORMAT),
      maxDate: moment().format(DATE_FORMAT),
      formatDate: {
        type: "string",
        mask: DATE_FORMAT,
      },
      masks: {
        input: DATE_FORMAT,
      },
    },

    onProgress: true,
  }),

  async created() {
    try {

      const employees_response = await API.apiClient.get("/employees", {
        params: this.$apiRequestParams.filters_list,
      });

      this.branches = await BranchService.getItems();
      this.employees = employees_response.data;

      this.getStatistic();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getStatistic() {
      try {
        const statistic_response = await API.apiClient.get("/statistic/overall", {
          params: {
            filters_query: this.filter_statistic,
          },
        });
        this.statistic = statistic_response.data;

        console.log('Statistic', this.statistic);
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },
  },
};
</script>

<style scoped>

</style>
